import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseResponse } from '../../shared/models/base-api-response.interface';
import { endpoint, httpOptions } from '../../shared/apis/endpoint';
import { map } from 'rxjs/operators';
import { Login } from '../models/login.interface';
import { environment as env } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private user: BehaviorSubject<BaseResponse>;

  public get userToken(): BaseResponse {
    return this.user.value;
  }

  constructor(
    private _router: Router,
    private http: HttpClient,
  ) {
    this.user = new BehaviorSubject<BaseResponse>(
      JSON.parse(localStorage.getItem('token'))
    )
  }

  login(req: Login): Observable<BaseResponse> {
    const requestUrl = `${env.api}${endpoint.LOGIN}`
    return this.http.post<BaseResponse>(requestUrl, req, httpOptions).pipe(
      map((resp: BaseResponse) => {
        if (resp.isSuccess) {
          localStorage.setItem('token', JSON.stringify(resp.data));
          this.user.next(resp.data);
          this.redirectByRole(resp.data);
        }
        else {
          Swal.fire({
            title: "Login",
            text: resp.message,
            icon: "error",
            confirmButtonText: "Aceptar"
          });
        }
        return resp;
      })
    )
  }

  logout() {
    localStorage.removeItem('token');
    this.user.next({
      isSuccess: false,
      data: null,
      message: null,
      errors: null
    });
    window.location.reload();
  }

  private redirectByRole(tokenData: any) {
    const roleName = tokenData?.given_name;
    switch (roleName) {
      case 'cc710ee8-763d-45d5-970c-484ee7150970':
      case '988143f5-55a3-4740-b480-228eeb8af416':
        this._router.navigateByUrl('menu/panel-admin');
        break;
      case '58ac50ce-c915-484c-9e7c-7eb0fd8551c6':
        this._router.navigateByUrl('menu/panel-contador');
        break;
      case 'f58b59d0-792d-40fa-9bf7-8d5fca2e858d':
        this._router.navigateByUrl('menu/panel-principal');
        break;
      case 'cbb9011c-6e73-4b40-b97f-7844fad610a2':
        this._router.navigateByUrl('menu/panel-externo');
        break;
      default:
        // Default redirection or error handling
        break;
    }
  }

}
