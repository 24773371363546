import { HttpHeaders } from "@angular/common/http"

export const endpoint = {

    //AUTH MODULE
    LOGIN: 'Auth/Login',

    // CATEGORIA MODULE
    GET_CATEGORIA: 'TablaCategoria',
    GET_CATEGORIA_BY_ID: 'TablaCategoria/',
    ADD_CATEGORIA: 'TablaCategoria',
    UPDATE_CATEGORIA: 'TablaCategoria?id=',
    DELETE_CATEGORIA: 'TablaCategoria?id=',

    // DEPOSITO MODULE
    GET_DEPOSITO: 'TablaDeposito',
    GET_DEPOSITO_BY_ID: 'TablaDeposito/',
    ADD_DEPOSITO: 'TablaDeposito',
    UPDATE_DEPOSITO: 'TablaDeposito?id=',
    DELETE_DEPOSITO: 'TablaDeposito?id=',

    // DEPOSITO EMPLEADO MODULE
    GET_DEPOSITO_EMPLEADO_EMPLEADO: 'TablaDepositoEmpleado',
    GET_DEPOSITO_EMPLEADO_BY_EMPLEADO: 'TablaDepositoEmpleado/empleado/',
    GET_DEPOSITO_EMPLEADO_BY_ID: 'TablaDepositoEmpleado/id/',
    ADD_DEPOSITO_EMPLEADO: 'TablaDepositoEmpleado',
    DELETE_DEPOSITO_EMPLEADO: 'TablaDepositoEmpleado?id=',

    // PERSONAL MODULE
    GET_PERSONAL: 'TablaPersonal',
    GET_PERSONAL_ID: 'TablaPersonal/',
    ADD_PERSONAL: 'TablaPersonal',
    UPDATE_PERSONAL: 'TablaPersonal?id=',
    DELETE_PERSONAL: 'TablaPersonal?id=',

    // REGISTRO MODULE
    GET_ALL_REGISTRO: 'TablaRegistros/all?id=',
    GET_ALL_REGISTRO_ID: 'TablaRegistros/all-dates?id=',
    GET_ALL_REGISTRO_INICIO: '&fechaInicio=',
    GET_ALL_REGISTRO_FIN: '&fechaFin=',
    GET_REGISTRO: 'TablaRegistros?id=',
    GET_REGISTRO_BY_ID: 'TablaRegistros/',
    ADD_REGISTRO: 'TablaRegistros',
    UPDATE_REGISTRO: 'TablaRegistros?id=',
    DELETE_REGISTRO_ID: 'TablaRegistros?id=',
    GET_COUNT_REGISTRO_INICIO: 'TablaRegistros/contador-registros?fechaInicio=',
    GET_COUNT_REGISTRO_FIN: '&fechaFin=',

    // ROL MODULE
    GET_ROL: 'TablaRol',
    GET_ROL_BY_ID: 'TablaRol/',
    ADD_ROL: 'TablaRol',
    UPDATE_ROL: 'TablaRol?id=',
    DELETE_ROL: 'TablaRol?id=',

    // TIPO DOCUMENTO MODULE
    GET_TIPO_DOCUMENTO: 'TablaTipoDocumento',
    GET_TIPO_DOCUMENTO_BY_ID: 'TablaTipoDocumento/',
    ADD_TIPO_DOCUMENTO: 'TablaTipoDocumento',
    UPDATE_TIPO_DOCUMENTO: 'TablaTipoDocumento?id=',
    DELETE_TIPO_DOCUMENTO: 'TablaTipoDocumento?id=',

}

export const httpOptions = {
    headers: new HttpHeaders({
        "Content-Type": "application/json"
    })
}