<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">Copyright {{ today | date:'y'}} © Castillo Safetty </p>
      <p class="mb-0">Creado por <a
          href="https://wa.me/543525619039?text=Buenas%21%20Te%20contacto%20desde%20la%20página%20de%20Castillo%20Safetty.">Castillo
          Solutions</a>
      </p>
    </div>
  </div>
</div>