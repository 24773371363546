import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "src/app/auth/service/login.service";
import { RolService } from "src/app/components/simple-page/services/rol.service";
import { BaseResponse } from "src/app/shared/models/base-api-response.interface";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public rolId: string;
  public rolName: string;
  public profileImg: "/src/assets/images/dashboard/user_profile.png";

  constructor(
    public router: Router,
    private authService: LoginService,
    private rolService: RolService) { }

  ngOnInit() {
    const token = localStorage.getItem("token");
    if (token) {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = new Date(tokenData.exp * 1000);
      const currentTime = new Date();

      if (expirationTime <= currentTime) {
        localStorage.removeItem("token");
        this.router.navigateByUrl('auth/login');
      } else {
        this.userName = tokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        this.rolId = tokenData['given_name'];
      }
    } else {
      this.router.navigateByUrl('auth/login');
    }
    this.getRolById();
  }

  logoutFunc() {
    this.authService.logout();
  }

  getRolById() {
    this.rolService.GetRolById(this.rolId).subscribe({
      next: (data: BaseResponse) => {
        if (data.isSuccess) {
          this.rolName = data.data.descripcion;
        } else {
          console.error(data.errors);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

}
