import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customFilter'
})
export class CustomFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(item => {
            // Modifica esta lógica según tus necesidades de filtrado
            return JSON.stringify(item).toLowerCase().includes(searchText);
        });
    }
}
