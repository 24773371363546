import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from '../service/login.service';
import { Login } from "../models/login.interface";
import Swal from "sweetalert2";
import { BaseResponse } from "src/app/shared/models/base-api-response.interface";
import { NavService } from "src/app/shared/services/nav.service";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public show: boolean = false
  usuario = {} as Login

  constructor(
    public router: Router,
    private authService: LoginService,
    private navService: NavService) {
  }

  ngOnInit() { }

  login(): void {
    if (this.usuario.username == null || this.usuario.username.trim() === '') {
      Swal.fire({
        title: "Campo obligatorio",
        text: "Debe ingresar su nombre de usuario",
        icon: "warning",
        confirmButtonText: "Aceptar"
      });
      return;
    }

    if (this.usuario.password == null || this.usuario.password.trim() === '') {
      Swal.fire({
        title: "Campo obligatorio",
        text: "Debe ingresar su contraseña",
        icon: "warning",
        confirmButtonText: "Aceptar"
      });
      return;
    }
    this.authService.login(this.usuario).subscribe({
      next: (data: BaseResponse) => {
        if (data.isSuccess) {
          const token = data.data;
          if (token) {
            var dataUser = JSON.parse(atob(token.split('.')[1]));
            const rol = dataUser['given_name'];
            if (rol == 'cc710ee8-763d-45d5-970c-484ee7150970' || rol == '988143f5-55a3-4740-b480-228eeb8af416') {
              // this.navService.MENUITEMSADMIN;
              this.router.navigate(['menu/panel-admin']);
            }
            if (rol == 'f58b59d0-792d-40fa-9bf7-8d5fca2e858d') {
              // this.navService.MENUITEMSVIGILANTE;
              this.router.navigate(['menu/panel-principal']);
            }
            if (rol == 'cbb9011c-6e73-4b40-b97f-7844fad610a2') {
              // this.navService.MENUITEMSEXTERNO;
              this.router.navigate(['menu/panel-externo']);
            }
            if (rol == '58ac50ce-c915-484c-9e7c-7eb0fd8551c6') {
              // this.navService.MENUITEMSCONTADOR;
              this.router.navigate(['menu/panel-contador']);
            }
          }
        }
        else {
          Swal.fire({
            title: "Error de inicio de sesión",
            text: data.message,
            icon: "error"
          });
        }
      }
    })
  }

  showPassword() {
    this.show = !this.show
  }
}
