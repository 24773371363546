<div class="container-fluid">
  <div class="row">
    <div class="col-xl-5" [ngStyle]="{'background-image': 'url(assets/images/login/logo_login.png)',
                                      'background-size': 'cover',
                                      'background-position': 'center center',
                                      'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/logo_login.png" alt="looginpage">
    </div>
    <div class="col-xl-7 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/login/logo.png" width="200" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/login/logo.png" width="200" alt="looginpage">
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form needs-validation">
              <h4>Inicia Sesión</h4>
              <div class="form-group">
                <label class="col-form-label">Username</label>
                <input class="form-control" [(ngModel)]="usuario.username" type="text"
                  placeholder="Ingresá tu nombre de usuario" name="username" id="username">
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input class="form-control" [(ngModel)]="usuario.password" [type]="show ? 'text' : 'password'"
                  name="password" placeholder="*********" name="password" id="password">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mt-4 mb-0">
                <button class="btn btn-primary btn-pill d-block w-100" type="button" (click)="login()">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>