import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from '../../../shared/models/base-api-response.interface';
import { endpoint, httpOptions } from '../../../shared/apis/endpoint';
import { environment as env } from '../../../../environments/environment';
import { Rol } from '../models/rol.interface';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(
    private _http: HttpClient) { }

  GetRol(): Observable<BaseResponse> {
    const requestUrl = `${env.api}${endpoint.GET_ROL}`
    return this._http.get(requestUrl).pipe(
      map((resp: any) => resp as BaseResponse)
    );
  }

  GetRolById(id: string): Observable<BaseResponse> {
    const requestUrl = `${env.api}${endpoint.GET_ROL_BY_ID}${id}`
    return this._http.get(requestUrl).pipe(
      map((resp: any) => resp as BaseResponse)
    );
  }

  AddRol(rol: Rol): Observable<BaseResponse> {
    const requestUrl = `${env.api}${endpoint.ADD_ROL}`
    return this._http.post(requestUrl, rol, httpOptions).pipe(
      map((resp: any) => resp as BaseResponse)
    );
  }

  UpdateRol(id: string, rol: Rol): Observable<BaseResponse> {
    const requestUrl = `${env.api}${endpoint.UPDATE_ROL}${id}`
    return this._http.put(requestUrl, rol, httpOptions).pipe(
      map((resp: any) => resp as BaseResponse)
    );
  }

  DeleteRol(id: string): Observable<BaseResponse> {
    const requestUrl = `${env.api}${endpoint.DELETE_ROL}${id}`
    return this._http.delete(requestUrl, httpOptions).pipe(
      map((resp: any) => resp as BaseResponse)
    );
  }

}
