import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(private router: Router, public navServices: NavService,
    public layout: LayoutService) {
    const token = localStorage.getItem("token");
    if (token) {
      var dataUser = JSON.parse(atob(token.split('.')[1]));
      const rol = dataUser['given_name'];
      if (rol === 'cc710ee8-763d-45d5-970c-484ee7150970' || rol === '988143f5-55a3-4740-b480-228eeb8af416') {
        this.navServices.itemsadmin.subscribe(menuItems => {
          this.menuItems = menuItems;
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter(items => {
                if (items.path === event.url) {
                  this.setNavActive(items);
                }
                if (!items.children) { return false; }
                items.children.filter(subItems => {
                  if (subItems.path === event.url) {
                    this.setNavActive(subItems);
                  }
                  if (!subItems.children) { return false; }
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url) {
                      this.setNavActive(subSubItems);
                    }
                  });
                });
              });
            }
          });
        });
      }
      if (rol === 'f58b59d0-792d-40fa-9bf7-8d5fca2e858d') {
        this.navServices.itemsvigilante.subscribe(menuItems => {
          this.menuItems = menuItems;
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter(items => {
                if (items.path === event.url) {
                  this.setNavActive(items);
                }
                if (!items.children) { return false; }
                items.children.filter(subItems => {
                  if (subItems.path === event.url) {
                    this.setNavActive(subItems);
                  }
                  if (!subItems.children) { return false; }
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url) {
                      this.setNavActive(subSubItems);
                    }
                  });
                });
              });
            }
          });
        });
      }
      if (rol === 'cbb9011c-6e73-4b40-b97f-7844fad610a2') {
        this.navServices.itemsexterno.subscribe(menuItems => {
          this.menuItems = menuItems;
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter(items => {
                if (items.path === event.url) {
                  this.setNavActive(items);
                }
                if (!items.children) { return false; }
                items.children.filter(subItems => {
                  if (subItems.path === event.url) {
                    this.setNavActive(subItems);
                  }
                  if (!subItems.children) { return false; }
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url) {
                      this.setNavActive(subSubItems);
                    }
                  });
                });
              });
            }
          });
        });
      }
      if (rol === '58ac50ce-c915-484c-9e7c-7eb0fd8551c6') {
        this.navServices.itemscontador.subscribe(menuItems => {
          this.menuItems = menuItems;
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter(items => {
                if (items.path === event.url) {
                  this.setNavActive(items);
                }
                if (!items.children) { return false; }
                items.children.filter(subItems => {
                  if (subItems.path === event.url) {
                    this.setNavActive(subItems);
                  }
                  if (!subItems.children) { return false; }
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url) {
                      this.setNavActive(subSubItems);
                    }
                  });
                });
              });
            }
          });
        });
      }
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }


}
